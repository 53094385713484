'use strict';

(function() {
  function WorkOrderResource($resource) {
    return $resource('api/v1/workorders/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('WorkOrder', WorkOrderResource);

})();
